<template>
  <div class="tariff-spec">
    <div v-if="os && os.svg" class="tariff-spec__os-logo l-hidden-ms-down">
      <asset-img type="svg" :src="os.svg" :alt="os.name" class="tariff-spec__os-logo-img" />
    </div>
    <div class="tariff-spec__content">
      <div v-if="os && os.name" class="tariff-spec__os-name medium-title">
        {{ os.name }}
      </div>
      <base-specs :list="list" class="standart-text" />
    </div>
  </div>
</template>

<script>
import BaseSpecs from '@/components/Specs/BaseSpecs';
import specsFormatting from '../../../mixins/specsFormatting';
import { BillMgrMeasure } from '@/models/BillMgr/measures';
export default {
  name: 'TariffSpec',
  components: { BaseSpecs },
  mixins: [specsFormatting],
  props: {
    os: {
      type: Object,
      default: () => {},
    },
    // cost: {
    //   type: Number,
    //   required: true,
    //validator: obj => isNumber(obj.value) && obj.unit,
    // },
    // datacentername: {
    //   type: String,
    //   default: '',
    // },
    disc: {
      type: Object,
      default: null,
      validator: obj => !obj || obj instanceof BillMgrMeasure,
    },
    mem: {
      type: Object,
      default: null,
      validator: obj => !obj || obj instanceof BillMgrMeasure,
    },
    cpu: {
      type: Object,
      default: null,
      validator: obj => !obj || obj instanceof BillMgrMeasure,
    },
  },
  computed: {
    list() {
      let arr = [];
      // if (this.datacentername) arr.push(this.datacentername);
      if (this.cpu) arr.push(this.cpu.toString());
      if (this.mem) arr.push(this.mem.toString());
      if (this.disc) arr.push(this.disc.toString());
      // if (this.cost || this.cost === 0) {
      //   arr.push(this.$n(this.cost, 'currency', this.$i18n.locale) + ' / месяц');
      // }
      return arr;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-spec {
  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }
  &__os-logo {
    flex: 0 0 2.5rem;
    +breakpoint(sm-and-up) {
      margin-right: 1.5rem;
    }
    &-img {
      display: block;
      max-width: 2.5rem;
    }
  }
  &__os-name {
    margin-bottom: 0.25rem;
  }
}
</style>
