<template>
  <div class="vps">
    <div class="vps-info">
      <div class="vps-info__content">
        <h1 class="vps-info__title page-title">
          {{ $t('title') }}
        </h1>
        <div v-if="list.length" class="vps-mode">
          <plain-button
            icon="grid"
            class="vps-mode__btn"
            watch-active
            :active="mode === 'grid'"
            @click="setMode('grid')"
          />
          <plain-button
            icon="list"
            class="vps-mode__btn"
            watch-active
            :active="mode === 'table'"
            @click="setMode('table')"
          />
        </div>
      </div>
      <div
        v-if="priceList.length && list.length"
        :class="{ visible: list.length }"
        class="vps-info__create"
      >
        <base-button :to="{ name: 'vpsOrder' }" class="vps-info__create-btn">
          {{ $t('newServer') }}
        </base-button>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" class="vps-info__loader">
        <base-loader class="vps-info__loader-item" />
      </div>
      <div v-else class="vps-content">
        <base-alert
          v-if="isServerError"
          :title="$t('errors.serverError.title')"
          icon="warn"
          class="vps__alert"
        >
          {{ $t('errors.serverError.text') }}
        </base-alert>
        <base-empty
          v-else-if="!list.length && !isServerError"
          :title="$t('empty.title')"
          :text="$t('empty.text')"
          :link="{ title: $t('createServer'), to: { name: 'vpsOrder' } }"
          class="vps__empty"
        />
        <div v-else class="vps-content__list">
          <transition name="fade" mode="out-in">
            <component :is="modeComponents[mode]" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TariffsGrid from './components/TariffsGrid';
import TariffsTable from './components/TariffsTable';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../../mixins/index';
import providerChange from '@/mixins/providerChange';
export default {
  name: 'VpsMain',
  components: {
    TariffsGrid,
    TariffsTable,
    BaseEmpty,
    BaseAlert,
  },
  mixins: [storeMixin, providerChange],
  data() {
    return {
      modeComponents: {
        grid: TariffsGrid,
        table: TariffsTable,
      },
      isServerError: false,
    };
  },
  computed: {
    mode() {
      return this.$store.state.moduleVPS.mode;
    },
    vmKey() {
      return this.$store.state.moduleVPS.key;
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    priceList() {
      return this.$store.state.moduleVPS.moduleVpsOrder.list;
    },
    idList() {
      return this.list.filter(i => i.status.code === 2).map(x => x.id);
    },
  },
  watch: {
    priceList(event) {
      // console.log('priceList', event);
    },
    list(event) {
      if (event && !this.priceList) this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchPricelist');
      // console.log('list', event);
    },
  },
  mounted() {
    if (!this.priceList.length) {
      // console.log('main mounted fetchPricelist');
      this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchPricelist');
    }
    if (!this.list.length) {
      this.fetch();
    }
    this.fetchPaymentCard();
  },
  methods: {
    fetchPaymentCard() {
      this.$store.dispatch('moduleBilling/modulePayment/fetchList').then(data => {
        // const cards = data.elem.filter(x => x.paymethod === '20').length > 0;
        const cards =
          data.elem.filter(x =>
            this.provider === '2' ? x.paymethod === '49' : x.paymethod === '47'
          ).length > 0;
        // console.log(cards);
        this.$store.dispatch('moduleVPS/setPaymentCard', cards);
      });
    },
    fetchPleskPanel() {
      if (!this.list.length) this.$store.dispatch('moduleVPS/fetchList');
      return this.idList.map(x =>
        this.$store.dispatch('moduleVPS/fetchPleskPanel', { id: x, elid: x })
      );
    },
    fetch() {
      this.fetchList()
        // .then(() => {
        // if (this.provider !== '2') {
        // this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchPricelist');
        // }
        // })
        .then(() => {
          // if (this.list.length && !this.vmKey) {
          if (this.list.length) {
            const tariff = this.list.find(item => this.tools.gotoserver.isEnable(item));
            // console.log(this.list.map(item => item._info.details.addons));
            if (this.provider !== '2') {
              this.$store.dispatch('moduleVPS/fetchDetail', { id: tariff.id }).then(() => {
                this.$store.dispatch('moduleVPS/getVmKey', tariff);
              });
            }
          }
        })
        .then(() => {
          // this.fetchPleskPanel();
          // this.$store.dispatch('moduleVPS/fetchNodeList');
        })
        .catch(e => {
          if (e.status && e.status === 520) this.isServerError = true;
        });
    },
    setMode(val) {
      this.$store.commit('moduleVPS/SET_MODE', val);
    },
    updateList() {
      this.$store.dispatch('moduleVPS/updateList');
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleVPS/reset', 16);
      this.fetch();
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "VPS Серверы",
      "newServer": "Новый сервер",
      "createServer": "Создать сервер",
      "empty": {
        "title": "На данный момент у вас нет ни одного VPS сервера",
        "text": "VPS сервер - это отличная альтернатива физическому серверу. Начните с небольшого сервера и увеличивайте ресурсы по мере необходимости. Серверы готовы к работе и не требуют администрирования. Забота о сервере - это наша ответственность."
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.vps {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-between, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__alert {
      margin-top: 2.5rem;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
      &-btn {
        min-width: 10rem;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
